const awsmobile = {

  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:ec4cc78d-3ea6-40a0-a6b4-f750ccd34fab",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_YZJj79Gyg",
  aws_user_pools_web_client_id: "3qtfsecubgrr61j47u8vkllup4",
  aws_access_key_id: "AKIA3FLD42X2IOPBVNMV",
  aws_secret_access_key: "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",
  s3_bucket: "vcip-aws-liveness",
  
};
export default awsmobile;

