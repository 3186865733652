import React from "react";
import { useEffect } from "react";
import { Loader } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';


function FaceLiveness({faceLivenessAnalysis}) {
    const [loading, setLoading] = React.useState(true);
    const [sessionId, setSessionId] = React.useState(null)
   

    // const endpoint = process.env.REACT_APP_ENV_API_URL ? process.env.REACT_APP_ENV_API_URL : ''

    useEffect(() => {
        /*
         * API call to create the Face Liveness Session
         */
        const fetchCreateLiveness = async () => {
            try {
              const response = await fetch(
                "https://livenessfe.algofolks.com/createLivenessSessionId"
              );
              const result = await response.json();

              if (result.status === "success") {
                setSessionId(result.data);
                setLoading(false);
              } else {
                console.error("Failed to create liveness session:", result);
              }
            } catch (error) {
              console.error("Error creating liveness session", error);
            }

        };
        fetchCreateLiveness();

    },[])

    /*
   * Get the Face Liveness Session Result
   */
    const handleAnalysisComplete = async () => {
        /*
         * API call to get the Face Liveness Session result
         */
       try {
         if (!sessionId) {
           console.error("Session ID is not available.");
           return;
         }

         const response = await fetch(
           `https://livenessfe.algofolks.com/getFaceLivenessResult/${sessionId}`
         );
         const result = await response.json();
         console.log("User Confidence score", result);

         if (result.status === "success") {
           const data = result.data;

           if (data.Status === "SUCCEEDED") {
             console.log("User is live");
             const faceLivenessScore = data.Confidence;
             localStorage.setItem("faceLivenessScore", faceLivenessScore);
             window.parent.postMessage(
              {
                type: "faceLivenessScore",
                faceLivenessScore: faceLivenessScore,
              },
              // "http://localhost:8080"
              "https://vcip-new.algofolks.com"
            );
           } else {
             console.log("User is not live");
           }
         } else {
           console.error("Failed to get liveness result:", result);
         }
       } catch (error) {
         console.error("Error getting liveness result", error);
       }
        faceLivenessAnalysis(sessionId);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <FaceLivenessDetector
                    sessionId={sessionId}
                    region="ap-south-1"
                    onAnalysisComplete={handleAnalysisComplete}
                    onError={(error) => {
                        console.error(error);
                      }}
                />
            )}
        </>
    );
}

export default FaceLiveness;
